import Styles from "./SectionTitle.module.css";

const SectionTitle = (props) => {
  const textColor = props.color ? "white" : "black";
  return (
    <div className={Styles.wrapper}>
    
      <h2
        className={Styles.title}
        style={{ color: textColor, border: "1px solid " + textColor }}
      >
        {props.title}
      </h2>
    </div>
  );
};
export default SectionTitle;
