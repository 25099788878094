import { Col,  Row } from 'react-bootstrap';
import Styles from './HeaderStrip.module.css';
import logo from './logo-dark-strip.png';


const ExamSelectStrip = (prop) => {
    return (<div className={Styles.topStrip}>
        <Row className={Styles.row}>
            <Col sm={4}>
                <div className={Styles.logoHolder}>
                    <img src={logo} width={240} alt='Logo'></img>
                </div>
            </Col>
            <Col sm={2}></Col>
            <Col sm={6}>
                <div className={Styles.fraCode}>CENTRE:{prop.student.code} </div>
                <div className={Styles.fraName}>{prop.student.franchisee}</div>

            </Col>
        </Row>
    </div>
    )
}
export default ExamSelectStrip;

