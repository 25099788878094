import "./App.css";
import LoginApp from './Components/Login/LoginApp/LoginApp';
import PageLoading from "./UI/PageLoading/PageLoading";

function App() {
  return <>
  <LoginApp></LoginApp>
  </>;
}

export default App;
