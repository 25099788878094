import { Col,  Row } from 'react-bootstrap';
import Styles from './StudentStrip.module.css';


const StudentStrip = ({ handleLogout, ...prop }) => {
    return (<div className={Styles.studentStrip}>
        <Row className={Styles.row}>
            <Col sm={3}>
                <div className={Styles.studentName}> <strong className={Styles.strong}>{prop.student.name}</strong></div>
            </Col>
            <Col sm={4}>
                <div className={Styles.studentName}> <strong className={Styles.strong}>{prop.student.regno}</strong></div>
            </Col>
            <Col sm={4}>
                <div className={Styles.studentName}> <strong className={Styles.strong}>{prop.student.course}</strong></div>
            </Col>
            <Col sm={1}>
                <button className={`btn btn-primary ${Styles.logout}`} onClick={handleLogout} >Logout</button>
            </Col>
        </Row>
    </div>
    )
}
export default StudentStrip;

