import React, { useEffect, useState } from "react";
import axios from "axios"; // if you're using axios
import LoginForm from "../LoginForm/LoginForm";
import ExamMain from "../../Exam/ExamMain/ExamMain";
import APIURL from '../../../apiConfig';

const LoginApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      localStorage.removeItem("timeLeft");
      localStorage.removeItem("answers");
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("timeLeft");
    localStorage.removeItem("answers");
    setIsLoggedIn(false);
  };

  const handleLogin = async (username, password) => {
    try {
      const response = await axios.get(APIURL.api_url+"user_studentInfo/login/" +
          username +
          "/" +
          password
      );

      if (response.data) {
        localStorage.setItem("token", response.data.studentID);
        localStorage.setItem("studentData", JSON.stringify(response.data)); // Store the student data
        setIsValid(true);
        setIsLoggedIn(true);
      } else {
        setIsValid(false);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setIsValid(false);
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <ExamMain handleLogout={handleLogout}></ExamMain>
      ) : (
        <LoginForm handleLogin={handleLogin} isValid={isValid} />
      )}
    </>
  );
};

export default LoginApp;
