import { useState, useEffect } from "react";
import Styles from "./ExamSelect.module.css";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios"; // if you're using axios
import APIURL from "../../../apiConfig";

const ExamSelect = ({ handleStartExam }) => {
  const [studentData, setStudentData] = useState("");
  const [qpStatus, setQpStatus] = useState(""); // Added new state for status
  const [displayStatus, setDisplayStatus] = useState("Loading"); // Added new state for status
  const [isError, setisError] = useState(true); // Added new state for status

  useEffect(() => {
    const savedStudentData = localStorage.getItem("studentData");
    if (savedStudentData) {
      setStudentData(JSON.parse(savedStudentData));
    }
  }, []);

  useEffect(() => {
    if (!studentData) {
      setDisplayStatus("Unable to Load");
      setisError(true);
    } else if (!studentData.papers) {
      setDisplayStatus("Unable to Retrieve Exam");
      setisError(true);
    } else if (qpStatus == "QP_PARTIAL") {
      setDisplayStatus("Generating Questions");
      setisError(true);
    } else if (qpStatus == "QP_EMPTY") {
      setDisplayStatus("Starting Quesitons");
      setisError(true);
    } else {
      setisError(false);
    }
  }, [studentData, qpStatus]);

  useEffect(() => {
    // Immediate execution
    if (studentData.studentID && qpStatus !== "QP_FULL") {
      getStatus(studentData.studentID);
    }

    // Setting up the interval for subsequent executions
    let interval;
    if (qpStatus !== "QP_FULL") {
      interval = setInterval(() => {
        if (studentData.studentID) {
          getStatus(studentData.studentID);
        }
      }, 10000); // 10 seconds interval
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [studentData.studentID, qpStatus]); // Add status as a dependency

  useEffect(() => {
    if (qpStatus === "QP_EMPTY" || qpStatus === "QP_PARTIAL") {
      generateQP(studentData.studentID);
    }
  }, [qpStatus]);

  const getStatus = (studentid) => {
    axios
      .get(APIURL.api_url + "exam_OnlineExam/getstatus/" + studentid)
      .then((response) => {
        if (response.data) {
          // Handle the successful response here
          setQpStatus(response.data);
          // For example, you might want to update some state based on this response
        } else {
          // Handle the case where the response does not contain the expected data
        }
      })
      .catch((error) => {
        console.error("Error fetching status", error);
      });
  };
  return (
    <>
      {!isError ? (
        <Container>
          <Row>
            <Col sm={2}></Col>
            <Col sm={8}>
              {studentData.papers.map((exam, index) => (
                <Col sm={12} key={index}>
                  <div className={Styles.examCard}>
                    <Row>
                      <Col sm={4}>
                        <h5 className="mt-2">{exam.paperName}</h5>
                      </Col>
                      <Col sm={2}>
                        <p className={`mt-2 ${Styles.code}`}>{exam.code}</p>
                      </Col>
                      <Col sm={2}>
                        <p className={`${Styles.duration} mt-2`}>
                          {" "}
                          {exam.time}
                        </p>
                      </Col>
                      <Col sm={2}>
                        <p className={`${Styles.duration} mt-2`}>
                          {exam.tMaxMarks} Questions
                        </p>
                      </Col>
                      <Col sm={2}>
                        <button
                          className={`btn btn-primary mt-2 ${Styles.button}`}
                          onClick={() => handleStartExam(exam.paperID)}
                          disabled={qpStatus !== "QP_FULL"}
                        >
                          Start Exam
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Col>
            <Col sm={2}></Col>
          </Row>
        </Container>
      ) : (
        <>{displayStatus}</>
      )}
    </>
  );
};

const generateQP = (studentid) => {
  axios
    .get(APIURL.api_url + "exam_OnlineExam/generateqp/" + studentid)
    .then((response) => {
      if (response.data) {
        // Do something with the data if it exists
      } else {
        // Do something if the data does not exist or is falsy
      }
    })
    .catch((error) => {
      console.error("Error Generating QP", error);
    });
};

export default ExamSelect;
