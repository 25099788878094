"use client";
import React, { useState } from "react";
import Styles from "./LoginForm.module.css";
import SectionTitle from "../../../UI/SectionTitle/SectionTitle";
import Logo from "../../../logo-full.png";

const LoginForm = ({handleLogin,isValid}) => {
  const [regNo, setRegno] = useState("");
  const [passwd, setPasswd] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(regNo, passwd);
  };

  return (
    <form onSubmit={handleSubmit}>
    <div className={Styles.container}>
      <div className={Styles.form}>
        <div className="row text-center mt-1 mb-1">
          <div className="mb-2">
            <img width={80} src={Logo} alt="RCSS Logo"></img>
          </div>
          <SectionTitle title="Exam Login"></SectionTitle>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="firstName" className={Styles["label-custom"]}>
                Reg. No.
              </label>
              <input
                type="text"
                className={`form-control ${Styles.formControl}`}
                id="firstName"
                placeholder="Enter your Reg. No."
                value={regNo}
                onChange={(e) => setRegno(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="lastName" className={Styles["label-custom"]}>
                Password
              </label>
              <input
                type="password"
                className={`form-control ${Styles.formControl}`}
                id="lastName"
                placeholder="Enter the Password"
                value={passwd}
                onChange={(e) => setPasswd(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mt-4">
              <div className={Styles.wrapper}>
                <button className={Styles.button} >
                  Login
                </button>
              </div>
            </div>
          </div>
          {!isValid && (
        <div className={`col-md-12 mt-3 ${Styles.notvalid}`}>
          Invalid Username or Password
        </div>
      )}
        </div>
      </div>
    </div>
    </form>
  );
};

export default LoginForm;
